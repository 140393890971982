import {QDD_IDENTITY_SERVER_BASE_URL} from '../constants';

export const tokenExchangeService = async (
  code: string,
  codeVerifier: string
) => {
  return fetch(
    `${process.env.BASE_API_URL}/${QDD_IDENTITY_SERVER_BASE_URL}/tokenExchange`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        code: code,
        code_verifier: codeVerifier,
        redirect_uri: window.loginDialog?.model?.loginLoadingPageUrl
      })
    }
  ).then((result) => {
    return result.json();
  });
};
