import {COOKIES, QDD_IDENTITY_SERVER_BASE_URL} from '../constants';
import {getCookie, removeCookie, getLogoutRedirectUrl, getUUID} from '../utils';

export async function logout() {
  const lslAuthDataCookie = getCookie(COOKIES.LSL_AUTH_DATA);
  const {memberId, token} = parseLslToken(lslAuthDataCookie);
  if (memberId && token) {
    await fetch(
      `${process.env.BASE_API_URL}/${QDD_IDENTITY_SERVER_BASE_URL}/${memberId}/revokeToken`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-Request-Id': getUUID(),
          Authorization: 'Bearer ' + token
        }
      }
    ).catch((error) => console.log('Revoking token failed', error));
  }
  await fetch(`${process.env.BASE_ACCOUNTS_URL}/logoff`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  }).catch((error) => console.log('Logoff failed', error));
  window.qff_auth?.logout();
  removeCookie(COOKIES.LSL_AUTH_DATA);
  removeCookie(COOKIES.QLFF_WL_SESS);
  window.location.assign(getLogoutRedirectUrl());
}

export function parseLslToken(value: string | null): {
  memberId: string;
  token: string;
} {
  if (!value) {
    return {memberId: '', token: ''};
  }
  if (value.indexOf('|') < 0) {
    return {memberId: '', token: ''};
  }
  return {
    memberId: value.split('|')[0] as string,
    token: value.split('|')[1] as string
  };
}
