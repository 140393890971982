import {QFF_OAUTH_ACCOUNTS_CLIENT_ID} from '../constants';
import {sanitiseURL, parseDomain} from '../utils';

const crypto = require('crypto');

export function getCodeVerifier(): string {
  return crypto
    .randomBytes(32)
    .toString('base64')
    .replace(/=/g, '')
    .replace(/\+/g, '-')
    .replace(/\//g, '_');
}

export function getCodeChallenge(codeVerifier: string) {
  return crypto
    .createHash('sha256')
    .update(codeVerifier)
    .digest('base64')
    .replace(/=/g, '')
    .replace(/\+/g, '-')
    .replace(/\//g, '_');
}

export function generateParams(
  loginRedirectUrl: string,
  codeChallenge: string
) {
  const clientId = `client_id=${QFF_OAUTH_ACCOUNTS_CLIENT_ID}`;
  const redirectUri = `redirect_uri=${sanitiseURL(loginRedirectUrl)}`;
  const scope = `scope=profile`;
  const responseType = `response_type=code`;
  const web = `web=true`;
  const challenge = `code_challenge=${codeChallenge}`;
  const method = `code_challenge_method=S256`;
  return `${clientId}&${redirectUri}&${scope}&${responseType}&${web}&${challenge}&${method}`;
}

export function login(
  oauthLoginSuccessRedirect: string = window.location.href,
  uiElementId: string = window.document.title
) {
  const codeVerifier = getCodeVerifier();
  const codeChallenge = getCodeChallenge(codeVerifier);
  const redirectParams = generateParams(
    parseDomain(window.loginDialog?.model?.loginLoadingPageUrl),
    codeChallenge
  );
  const state = btoa(redirectParams);
  const loginSuccessRedirect = parseDomain(oauthLoginSuccessRedirect);
  const storeData = {
    uiElementId,
    loginSuccessRedirect,
    state,
    codeVerifier
  };
  sessionStorage.setItem('oauth_state', JSON.stringify(storeData));
  window.location.href = `${process.env.BASE_ACCOUNTS_URL}/oauth?${redirectParams}&state=${state}`;
}
