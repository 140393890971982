import {
  expireCookie,
  getSessionObject,
  parseOauthUrl,
  setCookie
} from '../utils';
import {tokenExchangeService} from '../services/tokenExchangeService';
import {
  ACCESS_TOKEN,
  COOKIES,
  OAUTH_EVENTS,
  TOKEN_EXCHANGE_FAIL_MESSAGE
} from '../constants';

const tokenExchange = (): void | string => {
  const {isValid, queryParams} = parseOauthUrl(window.location.href);
  const {isValidSessionObject, state, codeVerifier, loginSuccessRedirect} =
    getSessionObject();
  if (isValid && isValidSessionObject && queryParams.state === state) {
    expireCookie(COOKIES.LSL_AUTH_DATA);
    tokenExchangeService(queryParams.code as string, codeVerifier)
      .then((result) => {
        if (result.hasOwnProperty(ACCESS_TOKEN)) {
          setCookie(
            COOKIES.LSL_AUTH_DATA,
            `${result.user_id}|${result.access_token}`
          );
          window.dispatchEvent(new Event(OAUTH_EVENTS.TOKEN_EXCHANGE_SUCCESS));
          window.location.assign(loginSuccessRedirect);
        } else {
          //print log for testing purpose
          console.debug(`bad request: ${JSON.stringify(result, null, 4)}`);
          window.dispatchEvent(new Event(OAUTH_EVENTS.TOKEN_EXCHANGE_FAILURE));
        }
      })
      .catch((reason) => {
        console.debug(`bad request: ${JSON.stringify(reason, null, 4)}`);
        window.dispatchEvent(new Event(OAUTH_EVENTS.TOKEN_EXCHANGE_FAILURE));
      });
  } else {
    console.log(
      JSON.stringify(
        {
          message: 'invalid request',
          validQueryParams: isValid,
          validSessionStorage: isValidSessionObject,
          validState: queryParams.state === state
        },
        null,
        4
      )
    );
    expireCookie(COOKIES.LSL_AUTH_DATA);
    window.dispatchEvent(new Event(OAUTH_EVENTS.TOKEN_EXCHANGE_FAILURE));
    return TOKEN_EXCHANGE_FAIL_MESSAGE;
  }
};

export default tokenExchange;
