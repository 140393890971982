import {COOKIES} from '../constants';
import {login} from './login';
import {getCookie, getCurrentURL} from '../utils';

const elementId = 'qdd-sso-login';

export function sso() {
  console.log('sso invoked');
  const qlffWlSessCookie = getCookie(COOKIES.QLFF_WL_SESS);
  const lslAuthDataCookie = getCookie(COOKIES.LSL_AUTH_DATA);
  if (qlffWlSessCookie && !lslAuthDataCookie) {
    login(getCurrentURL(), elementId);
  }
}
