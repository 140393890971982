import {
  DEFAULT_REGION,
  OAUTH_EVENTS,
  QUERY_PARAMS,
  SECURE_CONTENT_PATH_PATTERN_ARRAY,
  SESSION_OBJS,
  SESSION_STORAGE_KEY
} from './constants';
const crypto = window?.crypto || require('crypto');

export const getInitOAuth = () => {
  return {
    login: () => {},
    logout: () => Promise.resolve(),
    tokenExchange: () => {},
    sso: () => {},
    subscribeOauthTokenExchangeSuccess: (fn: () => void) =>
      window.addEventListener(OAUTH_EVENTS.TOKEN_EXCHANGE_SUCCESS, () => fn()),
    subscribeOauthTokenExchangeFailure: (fn: () => void) =>
      window.addEventListener(OAUTH_EVENTS.TOKEN_EXCHANGE_FAILURE, () => fn())
  };
};

export const parseQueryParams = (url: string): {[key: string]: string} => {
  try {
    const params = new URLSearchParams(new URL(url).search);
    return Object.fromEntries(params.entries());
  } catch (error) {
    return {};
  }
};

export function getCookie(name: string): string | null {
  const cookieName = `${encodeURIComponent(name)  }=`;
  const cookieValue = document.cookie
    .split(';')
    .map((cookie) => cookie.trim())
    .find((cookie) => cookie.startsWith(cookieName));

  return cookieValue
    ? decodeURIComponent(cookieValue.substring(cookieName.length))
    : null;
}

export function removeCookie(name: string) {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}

export const getCookieValue = (cookieKey: string) => {
  const cookie = document.cookie
    ?.split('; ')
    .find((splitCookie) => splitCookie.startsWith(cookieKey));
  return cookie ? cookie.split('=')[1] : '';
};

export const setCookie = (
  cookieName: string,
  cookieValue: string,
  expireDays?: number
) => {
  if (expireDays) {
    const d = new Date();
    d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
    const expires = `expires=${  d.toUTCString()}`;
    document.cookie = `${cookieName}=${cookieValue};${expires};SameSite=${process.env.LSL_COOKIE_LEVEL}path=/`;
  } else {
    document.cookie = `${cookieName}=${cookieValue};SameSite=${process.env.LSL_COOKIE_LEVEL}path=/`;
  }
};

export const expireCookie = (cookieName: string) => {
  setCookie(cookieName, '', 0);
};

//tokeExchange utils
export const parseOauthUrl = (url: string) => {
  const queryParams = parseQueryParams(url);
  return {
    isValid:
      queryParams.hasOwnProperty(QUERY_PARAMS.CODE) &&
      queryParams.hasOwnProperty(QUERY_PARAMS.STATE),
    queryParams
  };
};

export const getSessionObject = () => {
  const sessionString = sessionStorage.getItem(SESSION_STORAGE_KEY);
  const sessionObject = (sessionString && JSON.parse(sessionString)) || {};
  const isValidSessionObject =
    sessionObject.hasOwnProperty(SESSION_OBJS.STATE) &&
    sessionObject.hasOwnProperty(SESSION_OBJS.CODE_VERIFIER);
  return {
    isValidSessionObject,
    state: sessionObject[SESSION_OBJS.STATE] as string,
    codeVerifier: sessionObject[SESSION_OBJS.CODE_VERIFIER] as string,
    loginSuccessRedirect:
      sessionObject[SESSION_OBJS.LOGIN_SUCCESS_REDIRECT] ||
      process.env.OAUTH_LOGIN_LOADING_URL
  };
};

export function getUUID() {
  return crypto.randomUUID();
}

export function getCurrentURL() {
  const currentURL = window.location.href || '';
  return sanitiseURL(currentURL);
}

export function sanitiseURL(url: string) {
  if (!url) {
    return url;
  }
  return url.replace(/\/$/, '');
}

export function isSecureContentPath(pathname?: string) {
  const currentPathname = pathname || window.location.pathname;
  return SECURE_CONTENT_PATH_PATTERN_ARRAY.some((reg) => {
      return reg.test(currentPathname);
  });
}


export function getCurrentRegionLanguage(pathname?: string) {
  const currentPathname = pathname || window.location.pathname;
  const pathDetails = currentPathname?.split('/');
  if (
    pathDetails?.length >= 3 &&
    pathDetails[1]?.length === 2 &&
    pathDetails[2]?.length === 2
  ) {
    return `/${pathDetails[1]}/${pathDetails[2].replace(/\.html/, '')}`;
  }
  return DEFAULT_REGION;
}

export function getLogoutRedirectUrl() {
  return isSecureContentPath()
    ? `${window.location.origin}${getCurrentRegionLanguage()}.html`
    : window.location.href;
}

export function getOverwrittenLoadingPageURL() {
  //overwrite loading page url when oauth login function is called from cdn.*
  if (window.location.hostname.startsWith('cdn')) {
    return window.location.href;
  }
  return window.loginDialog.model.loginLoadingPageUrl;
}

export function parseDomain(
  redirectUrl: string = window.location.origin + window.location.pathname
) {
  try {
    const url = new URL(redirectUrl, window.location.toString());
    url.host = window.location.host; //replace external domains
    return url.toString();
  } catch {
    console.error('error occured due to invalid url');
  }

  return '';
}

export function requiredMockLoginDialog({
  hostname = '',
  loginDialog = {}
}): boolean {
  //only allow to overwrite loginDialog when it is in cdn and localhost, and loginDialog is missing from window object
  //also excludes prd environment
  return (
    process.env.ENV !== 'prd' &&
    Object.keys(loginDialog).length === 0 &&
    (hostname.startsWith('cdn') || hostname.startsWith('localhost'))
  );
}

export function requiredMockOauthConfig({
  hostname = '',
  oauthConfig = {}
}): boolean {
  //only allow to overwrite loginDialog when it is in cdn and localhost, and loginDialog is missing from window object
  //also excludes prd environment
  return (
    process.env.ENV !== 'prd' &&
    Object.keys(oauthConfig).length === 0 &&
    (hostname.startsWith('cdn') || hostname.startsWith('localhost'))
  );
}
