import {
  getInitOAuth,
  getOverwrittenLoadingPageURL,
  requiredMockLoginDialog,
  requiredMockOauthConfig
} from './utils';
import {OAuth} from './@types/global';
import {mockLoginDialog, mockOauthConfig, OAUTH_EVENTS} from './constants';
import {login} from './apis/login';
import {logout} from './apis/logout';
import tokenExchange from './apis/tokenExchange';
import {sso} from './apis/sso';

const oauth: OAuth = getInitOAuth();
(async () => {
  if (
    requiredMockLoginDialog({
      hostname: window.location.hostname,
      loginDialog: window.loginDialog
    })
  ) {
    window.loginDialog = mockLoginDialog;
    window.loginDialog.model.loginLoadingPageUrl =
      getOverwrittenLoadingPageURL();
  }
  if (
    requiredMockOauthConfig({
      hostname: window.location.hostname,
      oauthConfig: window.oauthConfig
    })
  ) {
    window.oauthConfig = mockOauthConfig;
    window.oauthConfig.successRedirectUrl = getOverwrittenLoadingPageURL();
  }

  oauth.login = login;
  oauth.logout = logout;
  oauth.tokenExchange = tokenExchange;
  oauth.sso = sso;
  window.oauth = oauth;
  window.dispatchEvent(new Event(OAUTH_EVENTS.INIT));
})();
